import * as React from "react";
import { createGlobalStyle } from "./styled-components";
import { theme } from "./theme";
// import '../assets/fonts/font.ttf';
const fontsPath = "/static/fonts";
export const FontsGlobalStyle = createGlobalStyle`
  @font-face {
  font-family: 'TitilliumWeb';
  src: local('TitilliumWeb'), local('TitilliumWeb'), url(${fontsPath}/font.ttf) format('truetype');
  font-weight: 100 900;
  font-style: normal;
  }
`;

export const ResetStyle = createGlobalStyle`
html {
    box-sizing: border-box;
    font-size: 10px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: ${theme.fontFamily.main};
    padding: 0;
    margin: 0;
    font-size: ${theme.fontSize.base};
    line-height: 1;
  }
  a {
    text-decoration: none;
    color: ${theme.colors.black.base};
  }
  input, button {
    outline: 0;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    line-height: 1;
    background: transparent;

    /* inherit font & color from ancestor */
    /* color: inherit;
    font: inherit; */

    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;
  }

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
      border: 0;
      padding: 0;
  }  
` ;

function GlobalStyle() {
  return (
    <>
      <ResetStyle />
      {/* <FontsGlobalStyle/> */}
    </>
  );
}

export { GlobalStyle };
